import React, { useContext } from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'

import { Context } from '../components/context'
import Footer from '../components/layout/footer'
import { classNames, enabled, pageTemplate } from '../utilities'

import styles from './page.module.scss'

const Page = ({ data: { pagesJson: page } }) => {
  const { elementStyle } = useContext(Context)

  return (
    <Layout title={page.title}>
      <main className={classNames(page, styles, 'main')} style={elementStyle(page)}>
        {enabled(page.rows).map((row, i) => (
          <div key={i} className={classNames(row, styles, 'row')} style={elementStyle(row)}>
            {enabled(row.columns).map((column, i) => (
              <div key={i} className={classNames(column, styles, 'column')} style={elementStyle(column)}>
                {enabled(column.blocks).map((block, i) => pageTemplate(block, i))}
              </div>
            ))}
          </div>
        ))}
        <Footer />
      </main>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query ($title: String!) {
    pagesJson(title: {eq: $title }) {
      title
      type
      rows {
        settings {
          disabled
          customClasses
          colors {
            textColor
            backgroundColor
          }
        }
        columns {
          settings {
            disabled
            customClasses
            colors {
              backgroundColor
              textColor
            }
          }
          blocks {
            title
            template
            columns {
              label
              content {
                template
                content
                buttonText
                color
                label
                style
                videoUrl
                settings {
                  disabled
                  colors {
                    textColor
                    backgroundColor
                  }
                  customClasses
                }
                alt
                description
                image
                title
              }
              settings {
                disabled
                colors {
                  textColor
                  backgroundColor
                }
                customClasses
              }
            }
            settings {
              disabled
              colors {
                textColor
                backgroundColor
              }
              customClasses
            }
            subtitle
            pageclipLink
            nameLabel
            methodLabel
            buttonLabel
            termsAndConditionsLabel
            author
            buttonLabel
            quote
            images {
              alt
              description
              image
              title
              settings {
                disabled
                colors {
                  textColor
                  backgroundColor
                }
                customClasses
              }
            }
            options {
              autoCycleDelay
              autoCyclePages
              clickCycleDelay
              showPauseButton
              showScrollButton
            }
            slides {
              body
              heading
              image
              settings {
                disabled
                colors {
                  textColor
                  backgroundColor
                }
              }
            }
            tabs {
              settings {
                disabled
                colors {
                  textColor
                  backgroundColor
                }
              }
              label
              content {
                videoUrl
                title
                template
                style
                label
                image
                description
                content
                color
                buttonText
                alt
                settings {
                  disabled
                  colors {
                    textColor
                    backgroundColor
                  }
                }
              }
            }
          }
        }
      }
      settings {
        disabled
        customClasses
        colors {
          textColor
          backgroundColor
        }
      }
    }
  }
`
